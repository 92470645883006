import { Link } from "react-router-dom";
import styled from "styled-components";
import { Landing } from "../../constants/routes/elm";

export const ButtonLaunApp = ({ text , onClick }: any) => {
  return <BtnLaunAppWapper onClick={onClick}>{text}</BtnLaunAppWapper>;
};
export const ButtonLink = ({ text , link }: any) => {
  return <Link to={link} ><BtnLinkWapper>{text}</BtnLinkWapper></Link>;
};
export const BtnLaunAppWapper = styled.button`
  display: flex;
  padding: 12px 24px 12px 19px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: var(--Base-White, #fff);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%; /* 15px */
  letter-spacing: -0.45px;
  font-family: PoppinsSemiBold;
  border-radius: 26px;
  border: 1px solid #fff;
  background: linear-gradient(101deg, #8d00be 19.54%, #4300d3 82.42%);
  &:active {
    transform: translateY(2px);
  }
`;
export const BtnLinkWapper = styled.button`
  display: flex;
  padding: 12px 24px 12px 19px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: var(--Base-White, #fff);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%; /* 15px */
  letter-spacing: -0.45px;
  font-family: PoppinsSemiBold;
  border-radius: 26px;
  border: 1px solid #fff;
  background: linear-gradient(101deg, #8d00be 19.54%, #4300d3 82.42%);
  &:active {
    transform: translateY(2px);
  }
`;