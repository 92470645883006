import styled from "styled-components";
import { TitleSection } from "./WalletFeatures";
const contentArr = [
  {
    title: "Safety & Transparency",
    des: [
      `ANZOAI Wallet ensures safety and transparency in DeFi 2.0, offering up to 220% APY profits. Its LP Burn mechanism limits risks, ensuring cryptographic accuracy and user trust.`,
    ],
    color: "#FFEA9F",
  },
  {
    title: "Profit Optimization",
    des: [
      `ANZOAI Wallet integrates AI to analyze on-chain big data, tailoring strategies for maximum profit. Investors benefit from accurate, AI-driven optimization for superior returns.`,
    ],
    color: "#5CE091",
  },
  {
    title: "Time & Effort Savings",
    des: [
      `ANZOAI Wallet's AI streamlines investment processes, saving time and effort. Its user-friendly interface simplifies access to DeFi opportunities, ensuring a seamless experience for investors.`,
    ],
    color: "#DC90FF",
  },
];
const WhyUs = () => {
  return (
    <WhyUsWapper>
      <WhyUsContainer>
        <TitleSection
          title="Why Us?"
          des={
            "Not only helps investors store assets securely and transparently but also saves time and effort in researching the market to find potential coins/tokens to increase your assets"
          }
        />
        <WhyUsGroup data-aos="fade-up">
          {contentArr.map((item) => (
            <WhyUsElm key={item.title} color={item.color}>
              <h3>
                {" "}
                <span></span> {item.title}
              </h3>
              {item.des.map((des) => (
                <p key={des}>{des}</p>
              ))}
            </WhyUsElm>
          ))}
        </WhyUsGroup>
      </WhyUsContainer>
    </WhyUsWapper>
  );
};
export default WhyUs;
export const WhyUsWapper = styled.div`
  width: 100%;
  padding: 100px 11vw 0px;
  .rfm-marquee , .rfm-initial-child-container{
    gap: 20px;
    justify-content: space-around;
  }
  .rfm-initial-child-container{
    width: 100%;
  }
  @media only screen and (max-width: 1280px) {
    padding: 100px 5vw 0px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 50px 5vw 0px;
  }
`;
export const WhyUsContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 72px;
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(
    259deg,
    rgba(32, 32, 32, 0.06) 3.36%,
    rgba(0, 0, 0, 0.53) 98.33%
  );
  backdrop-filter: blur(12.449999809265137px);
  padding: 60px 50px;
  @media only screen and (max-width: 1024px) {
    padding: 35px 25px;
    gap: 32px;
  }
`;

const WhyUsGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  flex-wrap: wrap;
  @media only screen and (max-width: 1024px) {
    gap: 15px;
  }
  @media only screen and (max-width: 820px) {
    gap: 36px;
  }
`;
const WhyUsElm = styled.div<{ color?: any }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc((100% - 72px) / 3);
  h3 {
    color: ${({ color }) => color};
    font-size: 24px;
    font-weight: 800;
    line-height: 20px; /* 83.333% */
    display: flex;
    gap: 5px;
    span {
      width: 5px;
      height: 24px;
      background-color: ${({ color }) => color};
      display: flex;
    }
  }
  p {
    color: var(--Base-White, #fff);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    padding-left: 10px;
    &:nth-child(2) {
      padding-top: -6px;
    }
  }
  @media only screen and (max-width: 1024px) {
    width: calc((100% - 30px) / 3);
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
    gap: 8px;
  }
`;
