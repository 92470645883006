import styled from "styled-components";
import Bg from "../../assets/images/landing/HeroBanner.png";
import { TokentomicsTextBoxText } from "../Landing/Tokenomics";
import { BtnLaunAppWapper } from "../../components/Button";

export const PreSaleWapper = styled.div`
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(${Bg});
    top: 0px;
    z-index: -1;
    background-size: cover;
    background-position: center;
  }
`;
export const PreSaleContainer = styled.div`
  padding: 130px 11vw 100px;
  h1 {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-family: PoppinsBold;
    font-size: 40px;
    font-weight: 700;
    line-height: 41px; /* 102.5% */
    letter-spacing: 2.252px;
    margin-bottom: 30px;
    span {
      color: #ac59ff;
    }
  }
  @media only screen and (max-width: 1280px) {
    padding: 130px 5vw 100px;
  }
`;

export const PreSaleContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const PreSaleBoxLeft = styled.div`
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(
    259deg,
    rgba(32, 32, 32, 0.06) 3.36%,
    rgba(0, 0, 0, 0.53) 98.33%
  );
  backdrop-filter: blur(12.449999809265137px);
  width: 48.62%;
  align-self: stretch;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const PreSaleBoxLeftTop = styled.div`
  padding: 33px 43px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  border-bottom: 1px solid #272727;
  img {
    width: 20%;
    min-width: 200px;
  }
  ${TokentomicsTextBoxText} {
    h4 {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
`;
export const PreSaleBoxLeftBottom = styled.div`
  padding: 20px 43px 33px;
  h6,
  h5 {
    color: rgba(240, 240, 240, 0.92);
    font-size: 15.936px;
    font-weight: 400;
    line-height: 23.904px; /* 150% */
    span {
      color: var(--Colors-Secondary-Colors-Blue-600, #1570ef);
      font-family: PoppinsBold;
      font-size: 17px;
      font-weight: 700;
      line-height: 23.904px;
    }
  }
  h5 {
    span {
      color: var(--Primary-Color, #ac59ff);
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
`;
export const CountDownGroup = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  height: 50px;
  p {
    color: rgba(240, 240, 240, 0.92);
    font-size: 15.936px;
    font-weight: 400;
    line-height: 23.904px; /* 150% */
    margin-right: 6px;
  }
  span {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 23.904px; /* 199.2% */
    border-radius: 7px;
    background: #131313;
    padding: 10px;
    position: relative;
    aspect-ratio: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-mask-image: linear-gradient(
          rgb(255, 255, 255) 0px,
          rgb(255, 255, 255) 0px
        ),
        linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      mask-image: linear-gradient(
          rgb(255, 255, 255) 0px,
          rgb(255, 255, 255) 0px
        ),
        linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      -webkit-mask-position-x: 0%, 0%;
      -webkit-mask-position-y: 0%, 0%;
      -webkit-mask-size: auto, auto;
      mask-size: auto, auto;
      -webkit-mask-repeat: repeat, repeat;
      mask-repeat: repeat, repeat;
      -webkit-mask-origin: content-box, border-box;
      mask-origin: content-box, border-box;
      -webkit-mask-clip: content-box, border-box;
      mask-clip: content-box, border-box;
      -webkit-mask-mode: match-source, match-source;
      mask-mode: match-source, match-source;
      -webkit-mask-composite: exclude;
      mask-composite: exclude;
      -webkit-animation-timeline: auto;
      animation-timeline: auto;
      -webkit-animation-range-start: normal;
      animation-range-start: normal;
      -webkit-animation-range-end: normal;
      animation-range-end: normal;
      inset: 0px;
      border-radius: 7px;
      padding: 1px;
      background: var(
        --Gradient-1,
        linear-gradient(110deg, #ffffff 4.72%, #fd82ff 101.81%)
      );
    }
  }
`;
export const ProogestLine = styled.div<{
  progess?: number;
  softcap?: number;
  hardcap?: number;
}>`
  width: 70%;
  position: relative;
  margin-top: 32px;
  margin-bottom: 15px;
  border-radius: 2px;
  background: var(--Colors-Primary-Colors-Gray-500, #667085);
  height: 6px;
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    width: ${({ progess }) => progess || 0}%;
    border-radius: 2px;
    z-index: 1;
  }
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    top: 50%;
    z-index: 2;
    transform: translateX(-50%) translateY(-50%);
    &::before {
      position: absolute;
      color: var(--gray-500, #6b7280);
      font-family: PoppinsMedium;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;
      top: 0%;
      left: 50%;
      content: "";
      transform: translateX(-50%) translateY(-100%);
    }
    &:first-child {
      left: ${({ softcap }) => softcap || 0}%;
      background-color: #1570ef;
      box-shadow: 0px 0px 7px 0px rgba(121, 109, 255, 0.86);
      &::before {
        ${({ softcap }) => `content: '${softcap || 0}%'`};
      }
    }
    &:last-child {
      &::before {
        ${({ hardcap }) => `content: '${hardcap || 100}%'`};
      }
      left: ${({ hardcap }) => hardcap || 100}%;
      background-color: #ac59ff;
      box-shadow: 0px 0px 7px 0px rgba(214, 67, 217, 0.98);
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const PreSaleBoxRight = styled.div`
  width: 40.62%;
  border-radius: 20px;
  background: rgba(2, 2, 2, 0.22);
  backdrop-filter: blur(28.399999618530273px);
  position: relative;
  padding: 22px 24px;
  align-self: stretch;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-mask-image: linear-gradient(
        rgb(255, 255, 255) 0px,
        rgb(255, 255, 255) 0px
      ),
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    mask-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px),
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-position-x: 0%, 0%;
    -webkit-mask-position-y: 0%, 0%;
    -webkit-mask-size: auto, auto;
    mask-size: auto, auto;
    -webkit-mask-repeat: repeat, repeat;
    mask-repeat: repeat, repeat;
    -webkit-mask-origin: content-box, border-box;
    mask-origin: content-box, border-box;
    -webkit-mask-clip: content-box, border-box;
    mask-clip: content-box, border-box;
    -webkit-mask-mode: match-source, match-source;
    mask-mode: match-source, match-source;
    -webkit-mask-composite: exclude;
    mask-composite: exclude;
    -webkit-animation-timeline: auto;
    animation-timeline: auto;
    -webkit-animation-range-start: normal;
    animation-range-start: normal;
    -webkit-animation-range-end: normal;
    animation-range-end: normal;
    inset: 0px;
    border-radius: 20px;
    padding: 1px;
    z-index: -1;
    background: var(
      --Gradient-1,
      linear-gradient(110deg, #ffffff 4.72%, #fd82ff 101.81%)
    );
  }
  h2 {
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 20px;
  }
`;
export const TabSelect = styled.div`
  border-radius: 50px;
  background: #2a2a2a;
  display: inline-flex;
  padding: 4px;
  align-items: center;
  margin-bottom: 14px;
`;
export const TabBtn = styled.div<{ ischoose?: boolean }>`
  p {
    color: rgba(255, 255, 255, 0.92);
    text-align: center;
    font-size: 13.861px;
    font-weight: ${({ ischoose }) => (ischoose ? 600 : 500)};
    line-height: 18.019px;
  }
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform-style: preserve-3d;
  cursor: pointer;
  &::after {
    position: absolute;
    border-radius: 26px;
    border: ${({ ischoose }) => (ischoose ? "1px" : "0")} solid #fff;
    background: linear-gradient(101deg, #8d00be 19.54%, #4300d3 82.42%);
    top: 50%;
    left: 50%;
    content: "";
    width: ${({ ischoose }) => (ischoose ? "calc(100% - 2px)" : "0%")};
    height: ${({ ischoose }) => (ischoose ? "calc(100% - 2px)" : "0%")};
    opacity: ${({ ischoose }) => (ischoose ? "1" : "0")};
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    transition: all 0.3s linear;
    transform-style: preserve-3d;
    perspective: 900px;
  }
`;
export const TabContent = styled.div`
  width: 100%;
  overflow: hidden;
`;
export const Tab1 = styled.div`
  width: calc(50% - 10px);
  ${TabBtn} {
    margin: 10px 0px;
    p {
      font-size: 16px;
    }
  }
  ${BtnLaunAppWapper} {
    margin: 10px 0px;
    width: 100%;
  }
  h6 {
    span {
      color: var(--Colors-Primary-Colors-Primary-500, #9e77ed);
      font-family: PoppinsMedium;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
    color: var(--text-on-surface-sub, #d0d5dd);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 6px;
  }
  h4 , h5 {
    color: rgba(240, 240, 240, 0.92);
    font-size: 15.936px;
    font-weight: 400;
    line-height: 23.904px; /* 150% */
  }
  h5{
    font-size: 12px;
  }
  .Tabble-Wapper {
    margin-top: 10px;
  }
`;

export const InputValue = styled.div`
  border-radius: 9px;
  border: 1px solid var(--Colors-Primary-Colors-Primary-500, #9e77ed);
  background: #010102;
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
  button {
    width: 69px;
    height: 38px;
    border-radius: 7.835px;
    background: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
      font-size: 14px;
      font-family: PoppinsBold;
      font-weight: 600;
      line-height: 17.411px; /* 124.364% */
      background: var(--a, linear-gradient(90deg, #c44cff 0%, #5c00fc 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  input {
    width: calc(100% - 79px);
    background-color: transparent;
    border: none;
    position: relative;
    z-index: 1;
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    height: 38px;
  }
`;
export const SelectBtnGroup = styled.div<{ len: Number }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: wrap;
  button {
    width: ${({ len }) => `calc((100% - (( ${len} - 1 ) * 3px)) / ${len})`};
    height: 100%;
    border-radius: var(--Parameters-Radius-sm, 6px);
    border: 0.871px solid var(--Colors-Primary-Colors-Primary-600, #7f56d9);
    background-color: transparent;
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    text-align: center;
    font-family: PoppinsBold;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    padding: 8px;
  }
  @media only screen and (max-width: 1180px) {
    button {
      font-size: 12px;
      min-width: 55px;
    }
  }
`;
export const ConvetPrice = styled.div`
  display: flex;
  border-radius: 9px;
  background: #1f1f1f;
  padding: 12px 16px;
  margin: 6px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-align: center;
    font-family: PoppinsBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.411px; /* 124.364% */
    background: var(--a, linear-gradient(90deg, #c44cff 0%, #5c00fc 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    display: flex;
    align-items: center;
    gap: 7px;
  }
`;

export const ClaimGroups = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;
export const ClaimBox = styled.div`
  position: relative;
  width: calc(50% - 6px);
  padding: 8px 24px;
  margin-bottom: 20px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-mask-image: linear-gradient(
        rgb(255, 255, 255) 0px,
        rgb(255, 255, 255) 0px
      ),
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    mask-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px),
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-position-x: 0%, 0%;
    -webkit-mask-position-y: 0%, 0%;
    -webkit-mask-size: auto, auto;
    mask-size: auto, auto;
    -webkit-mask-repeat: repeat, repeat;
    mask-repeat: repeat, repeat;
    -webkit-mask-origin: content-box, border-box;
    mask-origin: content-box, border-box;
    -webkit-mask-clip: content-box, border-box;
    mask-clip: content-box, border-box;
    -webkit-mask-mode: match-source, match-source;
    mask-mode: match-source, match-source;
    -webkit-mask-composite: exclude;
    mask-composite: exclude;
    -webkit-animation-timeline: auto;
    animation-timeline: auto;
    -webkit-animation-range-start: normal;
    animation-range-start: normal;
    -webkit-animation-range-end: normal;
    animation-range-end: normal;
    inset: 0px;
    border-radius: 20px;
    padding: 1px;
    z-index: -1;
    background: var(
      --Gradient-1,
      linear-gradient(110deg, #ffffff 4.72%, #fd82ff 101.81%)
    );
  }
  p {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.904px; /* 170.743% */
  }
  h3 {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-family: PoppinsBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px; /* 110% */
  }
`;
export const TextStatus = styled.div<{ issucsses: any }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: ${({ issucsses }) => (issucsses ? "#e8fbd4" : "#FFF3CC")};
  color: ${({ issucsses }) => (issucsses ? "#0e680d" : "#934900")};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
`;
export const Robot3 = styled.img`
  position: absolute;
  width: 30.1%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-60%) translateY(20%);
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
export const TabTranlateBox = styled.div<{ islate?: boolean }>`
  width: calc(200% + 20px);
  display: flex;
  gap: 20px;
  transition: all 0.3s linear;
  transform: translateX(
    ${({ islate }) => (islate ? "0" : "calc(-50% - 10px)")}
  );
  ${Tab1} {
    .Tabble-Wapper {
      transition: all 0.3s linear;
      height: 250px;
      overflow-y: auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    ${Tab1} {
      .Tabble-Wapper {
        height: auto;
        overflow-y: auto;
      }
    }
  }
`;
