import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { BscConnector } from "./bsc";
import { WalletConnector } from "./walletConnect";

const NETWORK_URL = process.env.REACT_APP_BSC_NETWORK_URL;
const NETWORK_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID ?? "5611");

export const walletconnect = new WalletConnectConnector({
  //@ts-ignore
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  infuraId: "8c05040c9f4748d489ab9a485bf52e06",
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

export const walletconnect1 = new WalletConnector({});

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 2000, 43114, 250, 10, 42, 56, 66, 97, 137, 42161, 204, 80001, 5611, 7001, 42161],
});

export const bsc = new BscConnector({
  supportedChainIds: [1, 3, 4, 5, 2000, 43114, 250, 10, 42, 56, 97, 137, 80001, 5611, 204, 7001, 42161],
});

export const LIST_NETWORK = [
  {
    name: "Arbitrum",
    idText: "0xa4b1",
    chainId: 42161,
    gif: "/assets/icon/arb.gif",
    image: "/assets/icon/icon-arb.svg",
    minBanner: "/assets/images/mintPage/Arbitrum.jpg",
    params: {
      chainId: "0xa4b1",
      chainName: "Arbitrum One",
      nativeCurrency: {
        name: "Arbitrum One",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://arb1.arbitrum.io/rpc"],
      blockExplorerUrls: ["https://arbiscan.io/"],
    },
    detail: {
      text1: `Dive into the NFT Luffy Arbitrum Airdrop Adventure!
      We have completed the interaction and launch of the ANZO Luffy collection on Arbitrum`,
      text2: `Arbitrum is the leading Layer 2 technology that empowers you to explore and build in the largest Layer 1 ecosystem, Ethereum.`,
      text3: `Mint Time: 08:00 AM UTC, 10th Oct`,
      contract: `https://arbiscan.io/address/0x8407Fff6031C9ef5E4B732006B3fb73088610De8`,
    },
  },
  {
    name: "opBNB",
    chainId: 204,
    idText: "0xcc",
    image: "/assets/icon/opbnb.png",
    gif: "/assets/icon/opBNB.gif",
    minBanner: "/assets/images/mintPage/opBNB.jpg",
    params: {
      chainId: "0xcc",
      chainName: "opBNB Mainnet",
      nativeCurrency: {
        name: "opBNB Mainnet",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org"],
      blockExplorerUrls: ["https://opbnbscan.com/"],
    },
    detail: {
      text1: `We have completed the interaction and launch of the ANZO Luffy collection on opBNB Chain.`,
      text2: `opBNB is a high-performance layer-2 solution within the BNB ecosystem, built using the OP Stack`,
      text3: `Mint Time: 08:00 AM UTC, 08th Oct`,
      contract: `https://opbnbscan.com/address/0xFbFea005196bB09DcFABae4d33c2C2b567167c42`,
    },
  },
  {
    name: "ZetaChain",
    chainId: 7001,
    idText: "0x1b59",
    image: "/assets/icon/icon-zeta.png",
    gif: "/assets/icon/zeta.gif",
    minBanner: "/assets/images/mintPage/Zetachain.jpg",
    params: {
      chainId: "0x1b59",
      chainName: "ZetaChain",
      nativeCurrency: {
        name: "ZetaChain",
        symbol: "aZETA",
        decimals: 18,
      },
      rpcUrls: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
      blockExplorerUrls: ["https://zetachain-athens-3.blockscout.com/"],
    },
    detail: {
      text1: `We have completed the interaction and launch of the ANZO Luffy collection on ZetaChain Testnet
      ZetaChain Marketplace is launching on the testnet. Users can claim $ZETA testnet Here.`,
      text2: `ZetaChain is an L1 blockchain and smart contract platform with built-in connectivity to all blockchains. It is the only public blockchain with smart contracts that can manage assets, data & liquidity on any chain, even Bitcoin.`,
      text3: `Mint Time: 08:00 AM UTC, 08th Oct`,
      contract: `https://zetachain-athens-3.blockscout.com/address/0x8407Fff6031C9ef5E4B732006B3fb73088610De8`,
    },
  },
  {
    name: "BNB",
    chainId: 56,
    idText: "0x38",
    image: "/assets/icon/BNBIcon.png",
    gif: "/assets/icon/opBNB.gif",
    minBanner: "/assets/images/mintPage/opBNB.jpg",
    params: {
      chainId: "0x38",
      chainName: "BNB",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com/"],
    },
    detail: {
      text1: `We have completed the interaction and launch of the ANZO Luffy collection on BNB Chain.`,
      text2: `opBNB is a high-performance layer-2 solution within the BNB ecosystem, built using the OP Stack`,
      text3: `Mint Time: 08:00 AM UTC, 08th Oct`,
      contract: `https://opbnbscan.com/address/0xFbFea005196bB09DcFABae4d33c2C2b567167c42`,
    },
  },
];

export const LIST_CONNECTIONS = [
  {
    name: "ANZO AI Collection",
    symbol: "ANZOAI",
    fee: "0,5$ + Fee gas",
  },
  {
    name: "ANZO AI Art",
    symbol: "ANZOAI",
    fee: "Free",
  },
  {
    name: "ANZO AI Animal",
    symbol: "ANZOAI",
    fee: "Free",
  },
  {
    name: "ANZO AI Robot",
    symbol: "ANZOAI",
    fee: "Free",
  },
  {
    name: "ANZO AI Nature",
    symbol: "ANZOAI",
    fee: "Free",
  },
  {
    name: "ANZO AI Sport",
    symbol: "ANZOAI",
    fee: "Free",
  },
];
