import {
  PreSaleWapper,
  PreSaleContainer,
  PreSaleContent,
  PreSaleBoxLeft,
  PreSaleBoxLeftTop,
  PreSaleBoxLeftBottom,
  CountDownGroup,
  ProogestLine,
  PreSaleBoxRight,
  TabSelect,
  TabBtn,
  TabContent,
  Tab1,
  InputValue,
  SelectBtnGroup,
  ConvetPrice,
  TabTranlateBox,
  ClaimGroups,
  ClaimBox,
  TextStatus,
  Robot3,
} from "./style";
import logo from "../../assets/images/LogoHorizontal.svg";
import { TokentomicsTextBoxText } from "../Landing/Tokenomics";
import { useEffect, useState } from "react";
import right from "../../assets/images/preSale/right.svg";
import { ButtonLaunApp } from "../../components/Button";
import Table from "../../components/table";
import robot from "../../assets/images/landing/Bot3.png";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId, useBalance } from "wagmi";
import changeNetwork from "../../utils/SelectNetwork";
import web3 from "web3";
import tokenABI from "../../integrateContract/ABI/USDT_ABI.json";
import anzoABI from "../../constants/abi/abiPreSaleAnzo.json";
import anzoClaimABI from "../../constants/abi/abiClaimAnzo.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";

const ListPushValue = [100, 500, 1000, 2000, 3000, 5000];
declare const window: Window & typeof globalThis & { ethereum: any };

const PreSales = () => {
  const w3 = window.ethereum ? new web3(window.ethereum) : new web3("");
  const [abiUSDT] = useState<any>(tokenABI);
  const [abiAnzo] = useState<any>(anzoABI);
  const [abiClaimAnzo] = useState<any>(anzoClaimABI);
  const [balance, setBalance] = useState<any>(0);
  const [price, setPrice] = useState<any>(0);
  const [priceMin, setPriceMin] = useState<any>(0);
  const [priceMax, setPriceMax] = useState<any>(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timeClaim, setTimeClaim] = useState<any>(0);
  const [preSaleStatus, setPreSaleStatus] = useState("");
  const [PERIOD, setPERIOD] = useState(0);

  const calculateTimeLeft = () => {
    const now = Math.floor(Date.now() / 1000);
    let timeLeft = {};

    const difference = Math.max(startTime - now, 1712336400000 - now);

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (24 * 3600)),
        hours: Math.floor((difference % 86400) / 3600),
        minutes: Math.floor((difference % 3600) / 60),
        seconds: Math.floor(difference % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };
  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    setPreSaleStatus(
      startTime - now > 0
        ? "Opening sale :"
        : endTime - now > 0
        ? "Sale is Going on :"
        : "Has end"
    );
  }, [endTime, startTime]);
  const calculateTimeClaim = () => {
    const now = Math.floor(Date.now() / 1000);
    const difference = timeClaim - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (3600 * 24)),
        hours: Math.floor((difference % (3600 * 24)) / 3600),
        minutes: Math.floor((difference % 3600) / 60),
        seconds: difference % 60,
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  const [startTimeClaim, setStartTimeClaim] = useState<any>(
    calculateTimeClaim()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setStartTimeClaim(calculateTimeClaim());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeClaim]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [endTime]);

  const [tabActive, setTabActive] = useState(false);
  const [amount, setAmount] = useState(0);
  const { open } = useWeb3Modal();
  const chainId = useChainId();
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [ToTal_Sell_ANZO, setToTal_Sell_ANZO] = useState<any>();
  const [totalRaisedANZO, settotalRaisedANZO] = useState<any>();
  useEffect(() => {
    if (address) {
      if (chainId !== 97) {
        changeNetwork("0x61");
      }
    }
  }, [address]);
  // const usdtContract = new w3.eth.Contract(
  //   abiUSDT,
  //   "0xE0FcfFF02765FE602B3118ec37ea7937bf23fC39"
  // );
  const anzoContract = new w3.eth.Contract(
    abiAnzo,
    "0x7Ebd3Baf5BE3012F1a1d10B83D7F2A3d60370C57"
  );
  const anzoClaimContract = new w3.eth.Contract(
    abiClaimAnzo,
    "0xb3020ABbd9Fad8BeFb10562803FCFeFDAEa31644"
  );

  useEffect(() => {
    if (chainId === 56) {
      if (address) {
        anzoContract.methods.tokenUsdt().call((error: any, res: any) => {
          if (!error) {
            const usdtContract = new w3.eth.Contract(abiUSDT, res);
            usdtContract.methods
              .balanceOf(address)
              .call((error: any, balance: any) => {
                if (!error) {
                  let _balance: any = web3.utils.fromWei(
                    web3.utils.toBN(balance),
                    "ether"
                  );
                  let bl = Math.round(parseFloat(_balance) * 10000) / 10000;
                  setBalance(bl);
                }
              });
          }
        });
      }
    }
  }, [chainId, address]);
  useEffect(() => {
    if (anzoContract && address) {
      anzoContract.methods.priceSell().call((error: any, priceTokent: any) => {
        if (!error) {
          let _priceTokent: any = web3.utils.fromWei(
            web3.utils.toBN(priceTokent),
            "ether"
          );
          let pr = Math.round(parseFloat(_priceTokent) * 10000) / 10000;
          setPrice(pr);
        }
      });
      anzoContract.methods
        .ToTal_Sell_ANZO()
        .call((error: any, ToTal_Sell_ANZO: any) => {
          if (!error) {
            let _ToTal_Sell_ANZO: any = web3.utils.fromWei(
              web3.utils.toBN(ToTal_Sell_ANZO),
              "ether"
            );
            let pr = Math.round(parseFloat(_ToTal_Sell_ANZO) * 10000) / 10000;
            setToTal_Sell_ANZO(pr);
          }
        });
      anzoContract.methods
        .totalRaisedANZO()
        .call((error: any, ToTal_Sell_ANZO: any) => {
          if (!error) {
            let _ToTal_Sell_ANZO: any = web3.utils.fromWei(
              web3.utils.toBN(ToTal_Sell_ANZO),
              "ether"
            );
            let pr = Math.round(parseFloat(_ToTal_Sell_ANZO) * 10000) / 10000;
            settotalRaisedANZO(pr);
          }
        });
      anzoContract.methods.priceMin().call((error: any, priceTokent: any) => {
        if (!error) {
          let _priceTokent: any = web3.utils.fromWei(
            web3.utils.toBN(priceTokent),
            "ether"
          );
          let pr = Math.round(parseFloat(_priceTokent) * 10000) / 10000;
          setPriceMin(pr);
        }
      });
      anzoContract.methods.priceMax().call((error: any, priceTokent: any) => {
        if (!error) {
          let _priceTokent: any = web3.utils.fromWei(
            web3.utils.toBN(priceTokent),
            "ether"
          );
          let pr = Math.round(parseFloat(_priceTokent) * 10000) / 10000;
          setPriceMax(pr);
        }
      });
      anzoContract.methods.timeEnd().call((error: any, _time: any) => {
        if (!error) {
          setEndTime(_time);
        }
      });
      anzoContract.methods.timeStart().call((error: any, _time: any) => {
        if (!error) {
          setStartTime(_time);
        }
      });
    }
  }, [anzoContract]);

  const handlerBuy = async () => {
    if (!address) {
      open();
    } else {
      if (preSaleStatus === "Opening sale :") {
        toast.error(`It's not yet time for the sale to start.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
            zIndex: 100,
          },
        });
        return;
      }
      if (preSaleStatus === "Has end") {
        toast.error(`The sales period has ended.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
            zIndex: 100,
          },
        });
        return;
      }
      if (amount < priceMin) {
        toast.error(`Minimum purchase of ${priceMin} USDT.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
            zIndex: 100,
          },
        });
        return null;
      } else if (priceMax && amount > priceMax) {
        toast.error(`I want to buy up to ${priceMax} USDT.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
            zIndex: 100,
          },
        });
        return null;
      }
      setLoading(true);
      let usdtContract: any;
      await anzoContract.methods.tokenUsdt().call((error: any, res: any) => {
        usdtContract = new w3.eth.Contract(abiUSDT, res);
        const amount = web3.utils.toWei(priceMax.toString());
        usdtContract.methods
          .approve("0x7Ebd3Baf5BE3012F1a1d10B83D7F2A3d60370C57", amount)
          .send({ from: address, gas: 2000000, gasPrice: "30000000000" })
          .then((receipt: any) => {
            console.log(receipt);
            toast.success("approve successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              style: {
                top: "100px",
              },
            });
            handleBuyIco(usdtContract);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
            toast.error(error?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              style: {
                top: "100px",
                zIndex: 100,
              },
            });
          });
      });
    }
  };
  const handleBuyIco = (usdtContract: any) => {
    const amountTemp = web3.utils.toWei(amount.toString(), "ether");
    anzoContract.methods
      .buyICO(amountTemp)
      .send({
        from: address,
        gas: 2000000,
      })
      .then((receipt: any) => {
        console.log(receipt);
        usdtContract.methods
          .balanceOf(address)
          .call((error: any, balance: any) => {
            if (!error) {
              let _balance: any = web3.utils.fromWei(
                web3.utils.toBN(balance),
                "ether"
              );
              let bl = Math.round(parseFloat(_balance) * 10000) / 10000;
              setBalance(bl);
            }
          });
        toast.success("successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
          },
        });
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            top: "100px",
            zIndex: 100,
          },
        });
      });
  };
  // -------------------------claim------------------------------
  const [amountClaimed, setAmountClaimed] = useState(0);
  const [amountCanClaim, setAmountCanClaim] = useState(0);

  useEffect(() => {
    if (anzoClaimContract && address) {
      anzoClaimContract.methods
        .VESTING_START_TIME()
        .call((error: any, _time: any) => {
          if (!error) {
            setTimeClaim(_time);
          }
        });
        anzoClaimContract.methods
        .VESTING_PERIOD()
        .call((error: any, _time: any) => {
          if (!error) {
            setPERIOD(_time);
          }
        });
      let Claimed = 0;
      anzoClaimContract.methods
        .claimedAmount(address)
        .call((error: any, amount: any) => {
          if (!error) {
            let _amount: any = web3.utils.fromWei(
              web3.utils.toBN(amount),
              "ether"
            );
            let am = Math.round(parseFloat(_amount) * 10000) / 10000;
            Claimed = am;
            setAmountClaimed(am);
          }
        });
      anzoClaimContract.methods
        .getTotalUserReceiveToken(address)
        .call((error: any, amount: any) => {
          if (!error) {
            let _amount: any = web3.utils.fromWei(
              web3.utils.toBN(amount),
              "ether"
            );
            let am = Math.round(parseFloat(_amount) * 10000) / 10000;
            setAmountCanClaim(am - Claimed);
          }
        });
    }
  }, [anzoClaimContract]);

  const [formattedTimeStart, setFormattedTimeStart] = useState('');
  const [formattedTimeEnd, setFormattedTimeEnd] = useState('');

  useEffect(() => {
    const date = new Date(timeClaim * 1000); 
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); 
    const day = ('0' + date.getDate()).slice(-2); 
    const hours = ('0' + date.getHours()).slice(-2); 
    const minutes = ('0' + date.getMinutes()).slice(-2); 
    const seconds = ('0' + date.getSeconds()).slice(-2); 
    const formatted = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    setFormattedTimeStart(formatted);
    let lastTime = Number(timeClaim) + Number(PERIOD);
    console.log('lastTime' , lastTime);
    
    const date1 = new Date(lastTime * 1000); 
    const year1 = date1.getFullYear();
    const month1 = ('0' + (date1.getMonth() + 1)).slice(-2); 
    const day1 = ('0' + date1.getDate()).slice(-2); 
    const hours1 = ('0' + date1.getHours()).slice(-2); 
    const minutes1 = ('0' + date1.getMinutes()).slice(-2); 
    const seconds1 = ('0' + date1.getSeconds()).slice(-2); 
    const formatted1 = `${year1}/${month1}/${day1} ${hours1}:${minutes1}:${seconds1}`;
    setFormattedTimeEnd(formatted1);
  }, [timeClaim , PERIOD]);

  const handlerClaim = async () => {
    if (!address) {
      open();
    } else {
      setLoading(true);
      await anzoClaimContract.methods
        .claim()
        .send({
          from: address,
          gas: 2000000,
          gasPrice: "30000000000",
        })
        .then((receipt: any) => {
          console.log(receipt);
          toast.success("successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              top: "100px",
            },
          });
          handleGetBlock();
          setEvents([])
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("An error has occurred. Please check and try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              top: "100px",
              zIndex: 100,
            },
          });
        });
      setLoading(false);
    }
  };

  const [events, setEvents] = useState<any[]>([]);
  const [currentBlock, setCurrentBlock] = useState<number>(0);

  const handleGetBlock = async () => {
    const latestBlockNumber = await w3.eth.getBlockNumber();
    setCurrentBlock(latestBlockNumber);
  };

  useEffect(() => {
    handleGetBlock();
  }, []);

  useEffect(() => {
    const loadEvents = async () => {
      console.log("currentBlock", currentBlock);
      const blockEvents = await anzoClaimContract.getPastEvents("allEvents", {
        fromBlock:
          currentBlock - 1024 > 39339701 ? currentBlock - 1024 : 39339701,
        toBlock: currentBlock,
      });
      setEvents((prevEvents) => [
        ...prevEvents,
        ...blockEvents?.slice().reverse(),
      ]);

      if (events.length < 10 && currentBlock >= 39339701) {
        setCurrentBlock((prevBlock) => prevBlock - 1024);
      }
    };

    console.log("events", events);
    loadEvents();
  }, [currentBlock]);
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    const now = Math.floor(Date.now() / 1000);
    setPreSaleStatus(
      startTime - now > 0
        ? "Opening sale :"
        : endTime - now > 0
        ? "Sale is Going on :"
        : "Has end"
    );
    if (completed) {
      return (
        <>
          <span>0D</span>
          <span>0H</span>
          <span>0M</span>
          <span>0S</span>
        </>
      );
    } else {
      return (
        <>
          <span>{`${days || 0}D`}</span>
          <span>{`${hours || 0}H`}</span>
          <span>{`${minutes || 0}M`}</span>
          <span>{`${seconds || 0}S`}</span>
        </>
      );
    }
  };

  return (
    <PreSaleWapper>
      <PreSaleContainer>
        <ToastContainer />
        <h1>
          ANZO <span>Pre-Sale</span>
        </h1>
        <PreSaleContent>
          <PreSaleBoxLeft>
            <PreSaleBoxLeftTop>
              <img src={logo} alt="" />
              <TokentomicsTextBoxText>
                <h3>
                  Total Supply: <span>1,000,000,000 $ANZO</span>
                </h3>
                <ul>
                  <li>Token Name: ANZO AI</li>
                  <li>Token Symbol: ANZO</li>
                  <li>Token Type : Governence, Utilities</li>
                  <li>Blockchain : BNB Chain</li>
                </ul>
                <h3>
                  Min Buy: <span>{priceMin} USDT</span>
                </h3>
                <h3>
                  Max Buy: <span>{priceMax} USDT</span>
                </h3>
                <h4>
                  Price: <span>{price || "--"} USDT</span>
                </h4>
              </TokentomicsTextBoxText>
            </PreSaleBoxLeftTop>
            <PreSaleBoxLeftBottom>
              <CountDownGroup>
                <p>{preSaleStatus}</p>
                {(startTime && endTime && (
                  <Countdown
                    date={
                      Date.now() < startTime * 1000
                        ? startTime * 1000
                        : endTime * 1000
                    }
                    renderer={renderer}
                  />
                )) || <p>----</p>}
              </CountDownGroup>
              <ProogestLine
                progess={ToTal_Sell_ANZO && (totalRaisedANZO / ToTal_Sell_ANZO ) * 100 > 100 ? 100 : (totalRaisedANZO / ToTal_Sell_ANZO ) * 100 || 0}
                softcap={20}
              >
                <span></span>
                <span></span>
              </ProogestLine>
              <h6>
                Softcap: <span>10,000,00 ANZO</span>
              </h6>
              <h5>
                Hardcap: <span>50,000,00 ANZO</span>
              </h5>
            </PreSaleBoxLeftBottom>
          </PreSaleBoxLeft>
          <Robot3 src={robot} />
          <PreSaleBoxRight>
            <TabSelect>
              <TabBtn
                ischoose={!tabActive}
                onClick={() => {
                  setTabActive(false);
                }}
              >
                <p>Pre-Sale</p>
              </TabBtn>
              <TabBtn
                ischoose={tabActive}
                onClick={() => {
                  setTabActive(true);
                }}
              >
                <p>Vesting</p>
              </TabBtn>
            </TabSelect>
            <TabContent>
              <TabTranlateBox islate={!tabActive}>
                <Tab1>
                  <h2>ANZO Pre-sale</h2>
                  <ButtonLaunApp
                    text={
                      address
                        ? `${address.slice(0, 5)}...${address.slice(-5)}`
                        : "Connect Wallet"
                    }
                    onClick={() => {
                      open();
                    }}
                  />
                  <h6>
                    <span>Your Balance:</span> {balance} USD
                  </h6>
                  <InputValue>
                    <input
                      type="text"
                      placeholder=""
                      value={amount || ""}
                      onChange={(e) => {
                        !isNaN(Number(e.target.value)) &&
                          setAmount(Number(e.target.value));
                      }}
                    />
                    <button>
                      <p>USDT</p>
                    </button>
                  </InputValue>
                  <SelectBtnGroup len={ListPushValue.length}>
                    {ListPushValue.map((value: any) => (
                      <button
                        key={value}
                        onClick={() => {
                          setAmount(amount + value);
                        }}
                      >
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                          .format(value)
                          .replace("$", "")
                          .slice(0, -3)}
                      </button>
                    ))}
                  </SelectBtnGroup>
                  <ConvetPrice>
                    <h3>
                      <img src={right} />
                      <span>{amount && price && amount / price}</span>
                    </h3>
                    <p>ANZO</p>
                  </ConvetPrice>
                  <ButtonLaunApp
                    text={loading ? "Loading..." : "Buy ANZO"}
                    onClick={handlerBuy}
                  />
                  <h4>Vesting Information:</h4>
                  <h5>Start Day : {formattedTimeStart}</h5>
                  <h5>End Day: {formattedTimeEnd}</h5>
                </Tab1>
                <Tab1>
                  <h2>ANZO Vesting</h2>
                  <ButtonLaunApp
                    text={
                      !startTimeClaim.days &&
                      !startTimeClaim.hours &&
                      !startTimeClaim?.minutes &&
                      !startTimeClaim?.seconds
                        ? "Claim Now"
                        : `${startTimeClaim?.days || 0}D ${
                            startTimeClaim?.hours || 0
                          }H ${startTimeClaim?.minutes || 0}M ${
                            startTimeClaim?.seconds || 0
                          }S`
                    }
                    onClick={
                      !startTimeClaim.days &&
                      !startTimeClaim.hours &&
                      !startTimeClaim?.minutes &&
                      !startTimeClaim?.seconds
                        ? handlerClaim
                        : () => {}
                    }
                  />
                  <ClaimGroups>
                    <ClaimBox>
                      <p>Balane Claim</p>
                      <h3>{amountCanClaim}</h3>
                    </ClaimBox>
                    <ClaimBox>
                      <p>Claimed</p>
                      <h3>{amountClaimed}</h3>
                    </ClaimBox>
                  </ClaimGroups>
                  <h4>Claiming History</h4>
                  <Table
                    data={events || []}
                    keyObj={[
                      {
                        th: "Date/Time",
                        key: "Date",
                        callback: (data: any) => {
                          const dateObject = new Date(
                            Number(data.returnValues.TimeClaimed) * 1000
                          );
                          const formattedDate =
                            dateObject.toLocaleDateString("en-US");
                          return formattedDate;
                        },
                      },
                      {
                        th: "Amount Anzo",
                        key: "Amount",
                        callback: (data: any) => {
                          return (
                            <>
                              {data.returnValues.Amount
                                ? new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(
                                    Number(
                                      web3.utils.fromWei(
                                        web3.utils.toBN(
                                          data.returnValues.Amount
                                        ),
                                        "ether"
                                      )
                                    )
                                  ).slice(1)
                                : "--"}
                            </>
                          );
                        },
                      },
                      {
                        th: "Status",
                        key: "Status",
                        callback: (data: any) => (
                          <TextStatus issucsses={1}>{"Claimed"}</TextStatus>
                        ),
                      },
                    ]}
                  ></Table>
                </Tab1>
              </TabTranlateBox>
            </TabContent>
          </PreSaleBoxRight>
        </PreSaleContent>
      </PreSaleContainer>
    </PreSaleWapper>
  );
};
export default PreSales;

const HistoryFake: any = [
  {
    Date: "16-01-2024, 23:59",
    Amount: "11763888888888888888889",
    Status: 1,
  },
  {
    Date: "16-01-2024, 23:59",
    Amount: "11763888888888888888889",
    Status: 2,
  },
  {
    Date: "16-01-2024, 23:59",
    Amount: "11763888888888888888889",
    Status: 1,
  },
  {
    Date: "16-01-2024, 23:59",
    Amount: "11763888888888888888889",
    Status: 2,
  },
  {
    Date: "16-01-2024, 23:59",
    Amount: "11763888888888888888889",
    Status: 1,
  },
];
