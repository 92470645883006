import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { LANDING_PATH } from ".";
import LayoutLandingWapper from "../../Layout/LandingLayOut";
import { Landing } from "./elm";
import PreSales from "../../page/Pre-Sale";

export const routes = [
  {
    path: LANDING_PATH.root,
    element: (
      <LayoutLandingWapper>
        <Outlet />
      </LayoutLandingWapper>
    ),
    breadcrumb: "LandingPage",
    children: [
      {
        index: true, 
        element: <Landing />,
      },
      {
        path: LANDING_PATH.preSale,
        children: [{ element: <PreSales />, index: true }],
        breadcrumb: "preSale",
      },
    ],
  },
  {
    path: "*", 
    element: <Navigate to={LANDING_PATH.root} />,
  },
];

export default function Router() {
  return useRoutes(routes);
}
