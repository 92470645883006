import { useEffect } from "react";
import styled from "styled-components";

const ChartSvg = () => {
  const valueArr = [
    {
      label: "Mining Pool - 55%",
      value: 55,
    },
    {
      label: "Ecosystem - 15%",
      value: 15,
    },
    {
      label: "Dev  - 10%",
      value: 10,
    },
    {
      label: "Marketing - 10%",
      value: 10,
    },
    {
      label: "Foundation Reserve - 5%",
      value: 5,
    },
    {
      label: "Private sales - 5%",
      value: 5,
    },
  ];
  const handleRenderTolltip = () => {
    let rotate = 0;
    return valueArr.map((value, index) => {
      rotate = rotate + value.value * 3.6;
      return (
        <TextValue
          key={value.label}
          id={`span${index + 1}`}
          value={rotate - value.value * 1.8}
        >
          <p>{value.label}</p>
        </TextValue>
      );
    });
  };
  return (
    <ChartWapper>
      {handleRenderTolltip()}
      <svg viewBox="0 0 412 413" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_251_488)">
          <circle
            cx="206"
            cy="206.5"
            // r="206"
            fill="white"
            fill-opacity="0.07"
            className="circle4"
          />
        </g>
        <circle
          cx="206"
          cy="206.5"
          // r="184.239"
          fill="white"
          fill-opacity="0.07"
          className="circle3"
        />
        <circle
          cx="206"
          cy="206.5"
          // r="161.754"
          fill="white"
          fill-opacity="0.16"
          className="circle2"
        />
        <circle
          cx="206"
          cy="206"
          // r="139"
          fill="white"
          fill-opacity="0.08"
          className="circle1"
        />
        <path
          className="elmDonus"
          d="M206.679 75.7902C225.615 75.8912 244.304 80.0963 261.459 88.1159C278.613 96.1354 293.825 107.779 306.047 122.244C318.268 136.708 327.208 153.651 332.251 171.904C337.294 190.156 338.32 209.285 335.258 227.973C332.196 246.66 325.119 264.461 314.514 280.15C303.91 295.839 290.03 309.042 273.832 318.85C257.633 328.658 239.501 334.838 220.684 336.963C201.867 339.089 182.813 337.11 164.835 331.162L205.98 206.79L206.679 75.7902Z"
          fill="#C852FF"
          onMouseEnter={() => {
            // @ts-ignore
            document.getElementById(`span1`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span1`).style.opacity = 0;
          }}
        />
        <path
          className="elmDonus"
          d="M164.841 331.164C145.213 324.672 127.401 313.626 112.86 298.931C98.3191 284.235 87.4627 266.307 81.1783 246.612L205.98 206.79L164.841 331.164Z"
          fill="#BE35FF"
          onMouseEnter={() => {
            // @ts-ignore
            document.getElementById(`span2`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span2`).style.opacity = 0;
          }}
        />
        <path
          className="elmDonus"
          d="M81.188 246.643C72.7719 220.289 72.9148 191.948 81.5963 165.68L205.98 206.79L81.188 246.643Z"
          fill="#AD00FF"
          onMouseEnter={() => {
            // @ts-ignore
            document.getElementById(`span3`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span3`).style.opacity = 0;
          }}
        />
        <path
          className="elmDonus"
          d="M81.606 165.651C90.2937 139.386 107.073 116.545 129.54 100.402L205.98 206.79L81.606 165.651Z"
          fill="#9300D8"
          onMouseEnter={() => {
            // @ts-ignore   
            document.getElementById(`span4`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span4`).style.opacity = 0;
          }}
        />
        <path
          className="elmDonus"
          d="M128.729 100.99C139.865 92.8586 152.215 86.5365 165.323 82.2571L205.98 206.79L128.729 100.99Z"
          fill="#7B00B5"
          onMouseEnter={() => {
            // @ts-ignore
            document.getElementById(`span5`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span5`).style.opacity = 0;
          }}
        />
        <path
          className="elmDonus"
          d="M165.325 82.2567C178.684 77.8952 192.659 75.7118 206.713 75.7904L205.98 206.79L165.325 82.2567Z"
          fill="#650094"
          onMouseEnter={() => {
            // @ts-ignore
            document.getElementById(`span6`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            // @ts-ignore
            document.getElementById(`span6`).style.opacity = 0;
          }}
        />
        <g filter="url(#filter1_d_251_488)">
          <rect
            x="160.055"
            y="160.555"
            width="91.9448"
            height="91.9448"
            rx="45.9724"
            fill="#EDD0FF"
            shape-rendering="crispEdges"
          />
          <path
            d="M210.405 197.77C210.529 197.785 210.647 197.799 210.766 197.819C210.821 197.824 210.875 197.834 210.929 197.844C210.959 197.844 210.984 197.849 211.008 197.859C212.403 198.131 213.628 198.803 214.686 199.881C216.1 201.319 216.807 203.054 216.807 205.076V210.484C216.807 210.776 216.817 211.062 216.837 211.349C220.519 215.585 227.494 216.317 229.749 216.44C229.185 216.386 228.646 216.272 228.132 216.095H228.127C227.099 215.744 226.18 215.151 225.364 214.315C223.965 212.876 223.263 211.146 223.263 209.12V203.711C223.263 203.459 223.253 203.202 223.238 202.955C219.655 198.798 213.095 197.957 210.746 197.794C210.742 197.794 210.742 197.79 210.742 197.79C210.618 197.785 210.504 197.774 210.405 197.77Z"
            fill="url(#paint0_linear_251_488)"
          />
          <path
            d="M216.836 211.35C216.89 212.225 217.034 213.07 217.261 213.875C217.814 215.828 218.867 217.568 220.424 219.101C222.837 221.474 225.738 222.66 229.139 222.66H238.082C238.082 220.949 237.484 219.491 236.277 218.285C235.071 217.078 233.613 216.475 231.897 216.475H230.498C230.375 216.475 230.251 216.46 230.133 216.455C230.034 216.455 229.925 216.451 229.796 216.44H229.747C227.493 216.317 220.518 215.585 216.835 211.349L216.836 211.35Z"
            fill="url(#paint1_linear_251_488)"
          />
          <path
            d="M201.945 191.536C201.945 193.276 202.553 194.739 203.775 195.936C204.991 197.127 206.469 197.725 208.209 197.725H209.568C209.855 197.725 210.132 197.74 210.403 197.77C212.381 197.878 219.445 198.575 223.227 202.94C223.231 202.95 223.236 202.955 223.236 202.955C223.068 199.91 221.867 197.29 219.623 195.095C217.2 192.722 214.289 191.536 210.893 191.536H201.945Z"
            fill="url(#paint2_linear_251_488)"
          />
          <path
            d="M210.745 197.796C210.75 197.805 210.76 197.815 210.765 197.82L210.77 197.825C210.824 197.83 210.879 197.835 210.928 197.845C210.958 197.845 210.983 197.85 211.007 197.86C218.353 198.76 223.262 205.824 223.262 205.824V203.713C223.262 203.461 223.252 203.204 223.237 202.957C219.653 198.8 213.094 197.959 210.745 197.796V197.796Z"
            fill="url(#paint3_linear_251_488)"
          />
          <path
            d="M216.806 209.04V210.484C216.806 210.776 216.816 211.062 216.835 211.349C220.518 215.585 227.493 216.317 229.747 216.44C229.184 216.386 228.645 216.272 228.131 216.095H228.126C225.299 215.408 219.817 213.324 216.806 209.04Z"
            fill="url(#paint4_linear_251_488)"
          />
          <path
            d="M201.945 191.536C203.023 193.79 207.892 193.879 207.892 193.879C207.892 193.879 212.905 193.919 215.979 195.362C220.398 197.433 223.068 202.618 223.227 202.939C223.231 202.949 223.236 202.955 223.236 202.955C223.068 199.91 221.867 197.29 219.623 195.095C217.2 192.722 214.289 191.536 210.893 191.536H201.945Z"
            fill="url(#paint5_linear_251_488)"
          />
          <path
            d="M216.836 211.35C216.89 212.225 217.034 213.07 217.261 213.875C217.814 215.828 218.867 217.568 220.424 219.101C222.837 221.474 225.738 222.66 229.139 222.66H238.082C238.082 222.66 237.014 220.059 233.509 219.371C232.87 219.246 232.215 219.221 231.564 219.257C229.043 219.396 219.005 219.429 216.835 211.349L216.836 211.35Z"
            fill="url(#paint6_linear_251_488)"
          />
          <path
            d="M180.613 205.89C180.392 207.013 180.275 208.171 180.275 209.367C180.275 214.602 182.492 219.259 185.939 222.239C186.003 222.294 186.069 222.351 186.134 222.408C185.062 222.179 184.039 221.833 183.058 221.38H183.053C181.464 220.646 179.995 219.618 178.64 218.295C176.349 216.067 174.915 213.479 174.341 210.542V210.538C174.147 209.548 174.046 208.516 174.046 207.441C174.046 203.19 175.577 199.558 178.639 196.551C181.702 193.54 185.355 192.033 189.61 192.033C190.012 192.033 190.409 192.046 190.802 192.076C192.4 192.184 193.908 192.525 195.329 193.083C195.191 193.079 195.053 193.07 194.915 193.07C188.422 193.07 182.924 197.774 181.006 204.283C181.001 204.301 181.001 204.309 181.001 204.309C180.958 204.439 180.919 204.568 180.885 204.702C180.863 204.771 180.85 204.845 180.834 204.914C180.781 205.117 180.734 205.316 180.691 205.519C180.682 205.562 180.669 205.601 180.665 205.644C180.648 205.726 180.63 205.804 180.618 205.886L180.613 205.89H180.613Z"
            fill="url(#paint7_linear_251_488)"
          />
          <path
            d="M205.21 207.44V222.775H198.684V207.44C198.684 204.909 197.802 202.719 196.04 200.888C194.282 199.061 192.135 198.144 189.595 198.144C187.056 198.144 184.905 199.06 183.147 200.888C182.066 202.011 181.328 203.263 180.904 204.646C180.904 204.646 180.9 204.646 180.904 204.641C180.918 204.585 180.935 204.529 180.952 204.473C180.964 204.425 180.987 204.369 181.003 204.309C182.913 197.785 188.415 193.069 194.917 193.069C195.055 193.069 195.194 193.077 195.332 193.082C196.965 193.721 198.481 194.654 199.885 195.885C200.126 196.097 200.364 196.322 200.602 196.551C203.677 199.557 205.211 203.19 205.211 207.44H205.21Z"
            fill="url(#paint8_linear_251_488)"
          />
          <path
            d="M198.679 216.663V222.775H189.612C188.403 222.775 187.245 222.65 186.135 222.408C182.58 219.436 180.277 214.702 180.277 209.367C180.277 208.17 180.395 207.009 180.619 205.885C180.637 205.791 180.649 205.721 180.658 205.683C180.554 206.249 180.507 206.836 180.507 207.441C180.507 209.976 181.384 212.15 183.142 213.955C184.9 215.76 187.05 216.663 189.591 216.663H198.678H198.679Z"
            fill="url(#paint9_linear_251_488)"
          />
          <path
            d="M199.883 195.885C185.434 193.652 181.668 202.326 181.002 204.308C182.912 197.785 188.414 193.069 194.916 193.069C195.054 193.069 195.193 193.077 195.331 193.082C196.964 193.721 198.48 194.654 199.884 195.885L199.883 195.885Z"
            fill="url(#paint10_linear_251_488)"
          />
          <path
            d="M189.898 222.774H189.612C188.403 222.774 187.245 222.649 186.135 222.407C182.58 219.435 180.277 214.701 180.277 209.366C180.277 208.17 180.395 207.008 180.619 205.885C180.351 207.457 179.353 216.454 189.897 222.774H189.898Z"
            fill="url(#paint11_linear_251_488)"
          />
          <path
            d="M198.677 222.775C198.677 222.775 198.075 217.912 194.252 216.663H198.681L198.677 222.775Z"
            fill="url(#paint12_linear_251_488)"
          />
          <path
            d="M195.329 193.083C195.329 193.083 176.777 190.366 174.341 210.538C174.147 209.548 174.046 208.516 174.046 207.441C174.046 203.19 175.577 199.558 178.639 196.551C181.702 193.54 185.355 192.033 189.61 192.033C190.012 192.033 190.409 192.046 190.802 192.076C192.4 192.184 193.908 192.525 195.329 193.083Z"
            fill="url(#paint13_linear_251_488)"
          />
          <path
            d="M201.968 210.536V222.773H198.635V207.44C198.635 204.907 197.754 202.72 195.994 200.885C202.52 202.943 201.969 210.536 201.969 210.536H201.968Z"
            fill="url(#paint14_linear_251_488)"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_251_488"
            x="-29.8924"
            y="-29.3924"
            width="471.785"
            height="471.785"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.9462" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_251_488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_251_488"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_251_488"
            x="131.855"
            y="132.355"
            width="148.345"
            height="148.345"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14.1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_251_488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_251_488"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_251_488"
            x1="225.267"
            y1="218.232"
            x2="210.118"
            y2="185.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_251_488"
            x1="238.082"
            y1="217.005"
            x2="216.836"
            y2="217.005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_251_488"
            x1="223.237"
            y1="197.245"
            x2="201.945"
            y2="197.245"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_251_488"
            x1="209.478"
            y1="195.492"
            x2="224.223"
            y2="204.702"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_251_488"
            x1="234.06"
            y1="222.166"
            x2="211.699"
            y2="205.285"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_251_488"
            x1="221.237"
            y1="198.92"
            x2="195.989"
            y2="185.358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_251_488"
            x1="238.082"
            y1="217.005"
            x2="216.836"
            y2="217.005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_251_488"
            x1="174.046"
            y1="207.221"
            x2="195.329"
            y2="207.221"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_251_488"
            x1="190.692"
            y1="199.349"
            x2="203.225"
            y2="225.409"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_251_488"
            x1="179.477"
            y1="211.282"
            x2="209.918"
            y2="230.712"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_251_488"
            x1="183.096"
            y1="200.608"
            x2="196.292"
            y2="194.282"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_251_488"
            x1="180.277"
            y1="214.329"
            x2="189.898"
            y2="214.329"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_251_488"
            x1="196.535"
            y1="216.657"
            x2="196.416"
            y2="221.834"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_251_488"
            x1="187.483"
            y1="194.102"
            x2="173.337"
            y2="211.686"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_251_488"
            x1="198.986"
            y1="222.586"
            x2="198.986"
            y2="199.532"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C915FF" />
            <stop offset="0.13" stop-color="#9B17DF" />
            <stop offset="0.26" stop-color="#7219C3" />
            <stop offset="0.4" stop-color="#501BAB" />
            <stop offset="0.55" stop-color="#351C99" />
            <stop offset="0.69" stop-color="#221D8C" />
            <stop offset="0.84" stop-color="#171D84" />
            <stop offset="1" stop-color="#141E82" />
          </linearGradient>
        </defs>
      </svg>
    </ChartWapper>
  );
};
export default ChartSvg;

const ChartWapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
  animation: translate 4s linear infinite;
  .circle1{
    animation: scale1 8s linear infinite;
  }
  .circle2{
    animation: scale2 8s linear infinite;
  }
  .circle3{
    animation: scale3 8s linear infinite;
  }
  .circle4{
    animation: scale4 8s linear infinite;
  }
  @keyframes scale1 {
    0%{
      r:0px;
      opacity: 0;
    }
    12.5%{
      r: 139px;
      opacity: 1;
    }
    50%{
      r: 139px;
      opacity: 1;
    }
    62.5%{
      r: 139px;
      opacity: 0;
    }
    100%{
      r: 139px;
      opacity: 0;
    }
  }
  @keyframes scale2 {
    0%{
      r:0px;
      opacity: 0;
    }
    12.5%{
      r: 139px;
      opacity: 1;
    }
    25%{
      r: 161.754px;
      opacity: 1;
    }
    50%{
      r: 161.754px;
      opacity: 1;
    }
    62.5%{
      r: 161.754px;
      opacity: 1;
    }
    75%{
      r: 161.754px;
      opacity: 0;
    }
    100%{
      r: 161.754px;
      opacity: 0;
    }
  }
  @keyframes scale3 {
    0%{
      r:0px;
      opacity: 0;
    }
    12.5%{
      r: 139px;
      opacity: 1;
    }
    25%{
      r: 161.754px;
      opacity: 1;
    }
    37.5%{
      r: 184.239px;
      opacity: 1;
    }
    50%{
      r: 184.239px;
      opacity: 1;
    }
    75%{
      r:184.239px;
      opacity: 1;
    }
    87.5%{
      r:184.239px;
      opacity: 0;
    }
    100%{
      r: 184.239px;
      opacity: 0;
    }
  }
  @keyframes scale4 {
    0%{
      r:0px;
      opacity: 0;
    }
    12.5%{
      r: 139px;
      opacity: 1;
    }
    25%{
      r: 161.754px;
      opacity: 1;
    }
    37.5%{
      r: 184.239px;
      opacity: 1;
    }
    50%{
      r: 206px;
      opacity: 1;
    }
    75%{
      r:206px;
      opacity: 1;
    }
    87.5%{
      r:206px;
      opacity: 1;
    }
    100%{
      r: 206px;
      opacity: 0;
    }
  }
  @keyframes translate {
    0%{
      top: 0px;
    }
    50%{
      top: 20px;
    }
    100%{
      top: 0px;
    }
  }
`;
const TextValue = styled.div<{ value?: any }>`
  position: absolute;
  z-index: 2;
  width: 64%;
  display: flex;
  justify-content: flex-end;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%)
    rotateZ(${({ value }) => value - 90}deg);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s linear;
  p {
    color: #fff;
    position: absolute;
    right: 0;
    transform: translateX(50%) rotateZ(-${({ value }) => value - 90}deg);
  }
`;
