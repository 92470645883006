import styled from "styled-components";
import { TitleSection } from "./WalletFeatures";
import { WhyUsWapper } from "./WhyUs";
import LogoHorizontal from "../../assets/images/LogoHorizontal.svg";
import { BtnLaunAppWapper, ButtonLaunApp } from "../../components/Button";
import TokentomicsBottom from "../../assets/images/landing/BgTokentnomic.png";
import ChartSvg from "./chart";
import RoBo from "../../assets/images/landing/Bot3.png";

const Tokenomics = () => {
  return (
    <WhyUsWapper>
      <TitleSection
        title="Tokenomics"
        des="AnzoAI Token (ANZO) is the driving force behind the AnzoAI ecosystem. This token enables seamless interactions, rewards, and access to services, underpinning the value exchanges that occur across the AnzoAI platform."
      />
      <TokentomicsWapper>
        <TokentomicsTextBox data-aos="fade-up">
          <TokentomicsTextBoxTitle>
            <img src={LogoHorizontal} />
          </TokentomicsTextBoxTitle>
          <TokentomicsTextBoxText>
            <h3>
              Total Supply: <span>1,000,000,000 $ANZO</span>
            </h3>
            <ul>
              <li>Token Name: ANZO AI</li>
              <li>Token Symbol: ANZO</li>
              <li>Token Type : Governence, Utilities</li>
              <li>Blockchain : BNB Chain</li>
            </ul>
            {/* <h3>
              Min Buy: <span>500 USDT</span>
            </h3>
            <h3>
              Max Buy: <span>5,000 USDT</span>
            </h3> */}
            {/* <h4>
              Price: <span>0.008 USDT</span>
            </h4> */}
          </TokentomicsTextBoxText>
        </TokentomicsTextBox>
        <TokentomicsTextBoxImg>
          <ChartSvg />
          <ImgBottom src={TokentomicsBottom} alt="" />
        </TokentomicsTextBoxImg>
        <Bot3 src={RoBo} alt="" />
      </TokentomicsWapper>
    </WhyUsWapper>
  );
};
export default Tokenomics;

const TokentomicsWapper = styled.div`
  display: flex;
  align-items: center;
  gap: 75px;
  position: relative;
  button {
    display: none;
  }
  @media only screen and (max-width: 820px) {
    gap: 0px;
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 540px) {
    button {
      display: flex;
      width: fit-content;
    }
  }
`;
const TokentomicsTextBox = styled.div`
  width: 60%;
  padding: 54px 60px;
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(
    259deg,
    rgba(32, 32, 32, 0.06) 3.36%,
    rgba(0, 0, 0, 0.53) 98.33%
  );
  backdrop-filter: blur(12.449999809265137px);
  display: flex;
  flex-direction: column;
  gap: 27px;
  @media only screen and (max-width: 1024px) {
    padding: 24px 30px;
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
    position: relative;
    z-index: 5;
  }
`;
const TokentomicsTextBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${BtnLaunAppWapper} {
    box-shadow: 0px 0px 14.7px 0px rgba(252, 120, 255, 0.8);
  }
  button {
    display: flex;
  }
  @media only screen and (max-width: 540px) {
    button {
      display: none;
    }
  }
`;
export const TokentomicsTextBoxText = styled.div`
  h3,
  li {
    color: rgba(240, 240, 240, 0.92);
    font-size: 15.936px;
    font-weight: 400;
    line-height: 23.904px; /* 150% */
    span {
      color: var(--Primary-Color, #ac59ff);
      font-family: PoppinsBold;
      font-size: 19.92px;
      font-weight: 700;
      line-height: 23.904px;
    }
  }
  ul {
    margin-top: 8px;
    padding-left: 20px;
    li {
      list-style: disc;
    }
  }
  h4 {
    color: rgba(240, 240, 240, 0.92);
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    span {
      color: var(--Primary-Color, #ac59ff);
      font-family: PoppinsBold;
      text-shadow: 0px 0px 19.5px rgba(89, 115, 255, 0.85);
      font-size: 24px;
      font-weight: 400;
      line-height: 23.904px; /* 99.6% */
    }
  }
`;
const TokentomicsTextBoxImg = styled.div`
  width: calc(40% - 75px);
  aspect-ratio: 414 / 590;
  display: flex;
  align-items: flex-end;
  position: relative;
  @media only screen and (max-width: 820px) {
    width: 60%;
  }
`;
const ImgBottom = styled.img`
  mix-blend-mode: hard-light;
  width: 100%;
`;
const Bot3 = styled.img`
  position: absolute;
  width: 26.6vw;
  max-width: 500px;
  aspect-ratio: 1/1;
  bottom: 0;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 820px) {
    width: 50%;
    top: ${40}vw;
    transform: translateX(0%);
    z-index: 0;
  }
`;
