import { Suspense, lazy, ElementType } from "react";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<></>}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const Landing = Loadable(lazy(() => import("../../page/Landing")));