import "./App.css";
import "antd/dist/antd.min.css";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext.jsx";
import { Suspense, useEffect, useState } from "react";
import Loader from "./components/Loader";
import { ModalConfirmProvider } from "./components/ProviderPopUp/confirm";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "./state";
import PopupEvent from "./components/Popup";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { arbitrum, bsc, opBNB, zetachainAthensTestnet } from "wagmi/chains";
import Router from "./constants/routes/router";
import { ToastContainer } from "react-toastify";

const projectId = "c06e8851ab55aa5954efec46c47d233e";
if (!projectId) {
  throw new Error("PROJECT_ID is not set");
}
const chains = [
  bsc,
  // arbitrum,
  // opBNB,
  // zetachainAthensTestnet,
];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
  themeVariables: {
    "--w3m-z-index": 9999,
  },
  chainImages: {
    7001: "/assets/icon/icon-zeta.png",
    204: "/assets/icon/opbnb.png",
  },
});

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Provider store={store}>
            <RefreshContextProvider>
              <Suspense fallback={<Loader />}>
                <ModalConfirmProvider>
                  <Router />
                </ModalConfirmProvider>
              </Suspense>
            </RefreshContextProvider>
          </Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </WagmiConfig>
  );
}

export default App;
