import {
  EventBlock,
  EventButton,
  EventClose,
  EventContainer,
  EventWrapper,
} from "./styled";

const PopupEvent = ({ eventShow, setEventShow }: any) => {
  return (
    <EventContainer className={eventShow ? "show" : ""}>
      <EventWrapper>
        <EventClose
          onClick={() => {
            setEventShow(false);
          }}
        >
          {/* <img src={close} alt="close" /> */}
        </EventClose>
        <EventBlock>
          <EventButton href="https://b2gc.finstable.co.th/en" target="_blank" rel="noreferrer">Go Event</EventButton>
          <EventButton href="" target="_blank" rel="noreferrer">Join Telegram</EventButton>
        </EventBlock>
      </EventWrapper>
    </EventContainer>
  );
};

export default PopupEvent;
