import styled from "styled-components";

export const EventContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  z-index: 2000;
  background-color: rgba(21, 21, 21, 0.8);
  transform: scale(0);
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
  &.show {
    transform: scale(1);
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }
`
export const EventButton = styled.a`
  border-radius: 14px;
  background: linear-gradient(90deg, #F68300 0%, #FEB500 100%);
  display: flex;
  width: 250px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 27px;
  font-weight: 800;
  line-height: 35px;
  &:hover {
    color: #fff;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 180px;
    font-size: 17px;
  }
  @media screen and (max-width: 767px) {
    width: calc(50% - 10px);
    font-size: 15px;
  }
`
export const EventClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 9px;
  border: 1px solid #E3A008;
  background: rgba(255, 255, 255, 0.66);
  cursor: pointer;
`
export const EventWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  width: 100%;
  height: 624px;
  margin: 0 auto;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 53vw;
  }
  @media screen and (max-width: 767px) {
    border-radius: 20px;
    max-width: 450px;
    height: 600px;
  }
`
export const EventBlock = styled.div`
  position: absolute;
  right: 90px;
  bottom: 60px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    right: 2.5vw;
    bottom: 4vw;
  }
  @media screen and (max-width: 767px) {
    right: 0;
    bottom: 80px;
    width: 100%;
    padding: 0 10px;
  }
`