import styled from "styled-components";
import LogoHorizontal from "../assets/images/LogoHorizontal.svg";
import { Link } from "react-router-dom";
import x from "../assets/x.svg";
import In from "../assets/in.svg";
import you from "../assets/you.svg";
import tele from "../assets/tele.svg";
import email from '../assets/email.svg';
const desArr = [
  {
    title: "Ecosystem",
    ref: [
      { link: "/", text: "DeFi Farming" },
      { link: "/", text: "ANZOAI Wallet" },
      { link: "/", text: "Launchpad" },
      { link: "/", text: "Marketplace" },
      { link: "/", text: "Exchange" },
    ],
  },
  {
    title: "Resources",
    ref: [
      { link: "/", text: "Pitchdeck" },
      { link: "/", text: "Tokenomics" },
      { link: "/", text: "Roadmap" },
      { link: "/", text: "Partnership" },
    ],
  },
  {
    title: "About Us",
    ref: [
      { link: "/", text: "Contact" },
      { link: "/", text: "Whitepaper" },
      { link: "/", text: "FAQs" },
    ],
  },
];
const Footer = () => {
  return (
    <FooterLayout>
      <BoxLogo>
        <img src={LogoHorizontal} />
        <p>Anzoai - Where DeFi Meets AI for Secure Asset Management and Profit Optimization!</p>
      </BoxLogo>
      <Boxdes>
        {desArr.map((item) => (
          <DesElm key={item.title}>
            <h3>{item.title}</h3>
            <>
              {item.ref.map((link) => (
                <Link to={link.link}>{link.text}</Link>
              ))}
            </>
          </DesElm>
        ))}
      </Boxdes>
      <CoppyRight>
        <h3>
          Copyright ANZO AI <span>2024</span>
        </h3>
        <BoxSocical>
          <Link to={"https://x.com/AnzoAI?t=u2Ktxr3scTYzVwrsQPLPEw&s=09"} target="_blank">
            <img src={x} />
          </Link>
          <Link to="mailto:Support@anzo.ai">
            <img src={email} />
          </Link>
          <Link to="https://t.me/AnzoAIchannel">
            <img src={tele} />
          </Link>
        </BoxSocical>
      </CoppyRight>
    </FooterLayout>
  );
};
export default Footer;

const FooterLayout = styled.div`
  width: 100vw;
  padding: 74px 11vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid var(--Stroke, #949394);
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(36.900001525878906px);
  @media only screen and (max-width: 1280px) {
    padding: 74px 5vw;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const BoxLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 35%;
  img {
    max-width: 211px;
  }
  p {
    color: var(--Base-White, #fff);
    font-size: 16px;
    font-weight: 400;
    line-height: 27.2px; /* 170% */
    letter-spacing: 0.8px;
    max-width: 331px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Boxdes = styled.div`
  display: flex;
  align-items: stretch;
  gap: 32px;
  width: 55%;
  @media only screen and (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;
const DesElm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc((100% - 64px) / 3);
  h3,
  a {
    text-align: right;
  }
  h3 {
    color: var(--Base-White, #fff);
    font-size: 18px;
    font-weight: 600;
    line-height: 39px; /* 216.667% */
    letter-spacing: 1.3px;
    margin-bottom: 8px;
  }
  a {
    color: var(--Base-White, #fff);
    font-size: 14px;
    font-weight: 300;
    line-height: 31.5px; /* 225% */
    letter-spacing: 0.9px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    h3,
    a {
      text-align: left;
      width: 100%;
    }
  }
`;
const CoppyRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  h3 {
    color: var(--Base-White, #fff);
    font-size: 16px;
    font-weight: 300;
    line-height: 16px; /* 100% */
    letter-spacing: 0.8px;
  }
  @media only screen and (max-width: 375px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
`;
const BoxSocical = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img{
    width: 32px;
  }
`;
