import styled from "styled-components";
import dolar from "../../assets/images/landing/dolar.png";
import dolar2 from "../../assets/images/landing/dolar2.png";
import dolar3 from "../../assets/images/landing/dolar3.png";
const contentArr = [
  {
    title: "Advanced Blockchain Integration",
    des: [
      `ANZOAI Wallet prioritizes asset security and real-time transparency on the decentralized network. With a user-friendly interface, it caters to both seasoned and novice investors, simplifying asset management effortlessly.`,
    ],
    img: dolar
  },
  {
    title: "Enhanced Security Measures",
    des: [
      `ANZOAI Wallet prioritizes asset security and real-time transparency on the decentralized network. With a user-friendly interface, it caters to both seasoned and novice investors, simplifying asset management effortlessly.`,
    ],
    img: dolar2
  },
  {
    title: "Exploring the Future AI",
    des: [
      `ANZOAI Wallet, powered by advanced AI, offers intuitive usability and comprehensive insights into digital assets. Make informed decisions and optimize your investment portfolio seamlessly with our cutting-edge technology.`,
    ],
    img: dolar3
  },
];
const WalletFeatures = () => {
  return (
    <WalletFeaturesWapper>
      <TitleSection
        title={"The Wallet Features"}
        des={`AnzoAI Wallet combines AI and blockchain to secure assets, optimize growth with OptiBot, an on-chain analyzer, ensuring security and maximizing potential.`}
      />
      <WalletFeaturesContainer>
        {contentArr.map((item) => (
          <WalletFeaturesElm key={item.title} data-aos="fade-up">
            <div>
              <img src={item.img} alt="" />
            </div>
            <h3>{item.title}</h3>
            {item.des.map((des) =><p key={des} >{des}</p>)}
          </WalletFeaturesElm>
        ))}
      </WalletFeaturesContainer>
    </WalletFeaturesWapper>
  );
};
export default WalletFeatures;

export const TitleSection = ({ title, des }: any) => {
  return (
    <TitleSectionWapper>
      <h1>{title}</h1>
      {des && <p>{des}</p>}
    </TitleSectionWapper>
  );
};
const TitleSectionWapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  max-width: 732px;
  width: 100%;
  margin: auto;
  h1 {
    color: var(--Base-White, #fff);
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    line-height: 120%; /* 48px */
  }
  p {
    color: var(--Base-White, #fff);
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
  @media only screen and (max-width: 820px) {
    h1 {
      font-size: 24px;
    }
  }
`;
const WalletFeaturesWapper = styled.div`
  width: 100%;
  padding: 0px 11vw;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 72px;
  @media only screen and (max-width: 1280px) {
    padding: 50px 5vw;
  }
`;
const WalletFeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
const WalletFeaturesElm = styled.div`
  width: calc((100% - 40px) / 3);
  align-self: stretch;
  position: relative;
  inset: 0px;
  border-radius: 20px;
  padding: 20px 30px;
  padding-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 40px;
  background: linear-gradient(
    259deg,
    #a873ff,
    #fff,
    rgba(24, 24, 24, 0.09),
    rgba(57, 0, 84, 0.43)
  );
  backdrop-filter: blur(11.5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s linear;
  background-size: 300%;
  background-position: 0% center;
  gap: 8px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    mask-image: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px),
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-position-x: 0%, 0%;
    -webkit-mask-position-y: 0%, 0%;
    mask-size: auto, auto;
    mask-repeat: repeat, repeat;
    mask-origin: content-box, border-box;
    mask-clip: content-box, border-box;
    mask-mode: match-source, match-source;
    mask-composite: exclude;
    animation-timeline: auto;
    animation-range-start: normal;
    animation-range-end: normal;
    inset: 0px;
    border-radius: 20px;
    padding: 1px;
    background: var(
      --Gradient-1,
      linear-gradient(110deg, #ffffff, #fd82ff, #ffffff, #82ff87)
    );
    transition: all 0.3s linear;
    background-size: 300%;
    background-position: 0% center;
  }
  h3 {
    color: var(--Base-White, #fff);
    font-family: PoppinsExtraBold;
    font-size: 24px;
    font-weight: 800;
    line-height: 20px; /* 83.333% */
    transition: all 0.3s linear;
  }
  p {
    color: var(--Base-White, #fff);
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    transition: all 0.3s linear;
    margin-bottom: 10px;
  }
  div {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    top: 0px;
    background: var(
      --Not-Focus,
      linear-gradient(
        259deg,
        rgba(118, 37, 199, 0.7),
        rgba(0, 0, 3, 1),
      )
    );
    backdrop-filter: blur(60px);
    transition: all 0.3s linear;
    background-size: 300%;
    background-position: 100% center;
    transform: translateY(-50%);
    right: 10%;
    margin-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &:hover {
    backdrop-filter: blur(6.900000095367432px);
    background-position: 100% center;
    h3,
    p {
      color: #000;
    }
    &::before {
      background-position: 100% center;
    }
    /* div {
      background-position: 0 center;
    } */
  }
  div {
    width: 25%;
    max-width: 90px;
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
    align-items: flex-start;
    padding: 20px 30px;
    h3 {
      font-size: 24px;
    }
    div {
      position: relative;
      transform: none;
      width: 12%;
      top: 0px;
      left: 0px;
    }
  }
`;
