import styled from "styled-components";
import Bg from "../assets/images/BgLayout.png";
import Logo from "../assets/images/Logo.svg";
import { Link } from "react-router-dom";
import { LANDING_PATH } from "../constants/routes";
import { ButtonLaunApp, ButtonLink } from "../components/Button";
import Footer from "./footer";
import { useContext, useState } from "react";
import { ModalConfirmContext } from "../components/ProviderPopUp/confirm";
import tele from "../assets/images/landing/Tele.png";

export const ArrLink = [
  { title: "Home", link: "/" },
  { title: "Features", link: "/features" },
  { title: "Community", link: "/community" },
  { title: "Docs", link: "/docs" },
];

const LayoutLandingWapper = ({ children }: any) => {
  return (
    <>
      <HeaderLanding />
      <LyoutContainer>
        {children}
        <Footer />
      </LyoutContainer>
    </>
  );
};
export default LayoutLandingWapper;

const HeaderLanding = () => {
  const { onOpen, isShowModal, onClose }: any = useContext(ModalConfirmContext);
  console.log(isShowModal);

  return (
    <HeaderWapper>
      <Link to={LANDING_PATH.root}>
        <img src={Logo} alt="Logo" />
      </Link>
      <MenuLinkBox>
        {ArrLink.map((item) => {
          return (
            <Link to={item.link} key={item.link} >
              {item.title}
            </Link>
          );
        })}
      </MenuLinkBox>
      <ButtonWapper>
        <ButtonLink text={"Launch App"} />
        {/* <ButtonLink text={"Launch App"} link={LANDING_PATH.preSale} /> */}
        <MenuMobileBtn
          isshow={isShowModal}
          onClick={() => {
            !isShowModal ? onOpen(<MenuMobile />) : onClose();
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </MenuMobileBtn>
      </ButtonWapper>
    </HeaderWapper>
  );
};
const MenuMobile = () => {
  const { onClose }: any = useContext(ModalConfirmContext);
  return (
    <MenuMobileWapper>
      {ArrLink.map((item) => {
        return (
          <Link to={item.link} key={item.link} onClick={onClose}>
            {item.title}
          </Link>
        );
      })}
      <BoxJoinTele onClick={onClose}>
        <a href="/" target="_blank">
          <div>
            <img src={tele} />
            <p>Join Telegram</p>
          </div>
          <button>Join Now</button>
        </a>
      </BoxJoinTele>
    </MenuMobileWapper>
  );
};
const LyoutContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  margin: 0px auto;
  background: #07030a;
  z-index: 1;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0px;
    padding: 0px;
  }
  &::after {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-image: url(${Bg});
    background-size: contain;
    top: calc(100vh - 1px);
    left: 50%;
    transform: translateX(-50%);
  }
`;
const HeaderWapper = styled.header`
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 0px 11vw;
  height: 70px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(226, 232, 255, 0.2);
  &::after {
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
  }
  @media only screen and (max-width: 1280px) {
    padding: 0px 5vw;
  }
  @media only screen and (max-width: 380px) {
    a {
      width: 100px;
      img {
        width: 100px;
      }
    }
  }
`;
const ButtonWapper = styled.div`
  min-width: 157px;
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  align-items: center;
  @media only screen and (max-width: 380px) {
    a {
      width: auto;
    }
  }
`;
const MenuLinkBox = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  a {
    font-size: 15px;
    color: #fff;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const MenuMobileBtn = styled.button<{ isshow?: boolean }>`
  width: 38px;
  height: 38px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  span {
    width: 19px;
    height: 1.5px;
    background: #ac59ff;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    border-radius: 5px;
    transition: all 0.3s linear;
    &:first-child {
      transform: ${({ isshow }) =>
        isshow
          ? `translateX(-50%) translateY(calc(-50%)) rotateZ(45deg)`
          : `translateX(-50%) translateY(calc(-50% - 5px))`};
    }
    &:nth-child(2) {
      opacity: ${({ isshow }) => (isshow ? `0` : `1`)};
    }
    &:last-child {
      transform: ${({ isshow }) =>
        isshow
          ? `translateX(-50%) translateY(calc(-50%)) rotateZ(-45deg)`
          : `translateX(-50%) translateY(calc(-50% + 5px))`};
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
const MenuMobileWapper = styled.div`
  width: 100%;
  padding: 40px 5vw;
  background-color: #000000;
  backdrop-filter: blur(11.5px);
  border-radius: 0px 0px 30px 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
  a {
    color: rgba(240, 240, 240, 0.92);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const BoxJoinTele = styled.div`
  max-width: 465px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background: rgb(20, 20, 25);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 0px 0px 1px inset,
    rgba(101, 209, 110, 0.08) 0px 0px 35px 0px,
    rgb(101, 209, 110) 0px 0px 0px 0px inset;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      max-width: 30px;
    }
    p {
      color: rgba(240, 240, 240, 0.68);
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }
  }
  button {
    text-align: center;
    font-family: PoppinsMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px;
    display: flex;
    padding: 4px 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #fff;
    background: linear-gradient(101deg, #8d00be 19.54%, #4300d3 82.42%);
  }
`;
